.title-jungle {
  font-family: "jungle", Helvetica, sans-serif, monospace;
  font-size: 60px;
  color: #fff;
}

.subtitle-jungle {
  font-family: "jungle", Helvetica, sans-serif, monospace;
  font-size: 30px;
}

.p-shadow {
  -webkit-text-stroke: 3px rgb(80, 6, 95);
  text-shadow: 1px 1px 0 rgb(80, 6, 95), -1px -1px 0 rgb(80, 6, 95),
    1px -1px 0 rgb(80, 6, 95), -1px 1px 0 rgb(80, 6, 95),
    1px 1px 0 rgb(80, 6, 95);
}

.y-shadow {
  -webkit-text-stroke: 3px rgb(214, 142, 8);
  text-shadow: 1px 1px 0 rgb(214, 142, 8), -1px -1px 0 rgb(214, 142, 8),
    1px -1px 0 rgb(214, 142, 8), -1px 1px 0 rgb(214, 142, 8),
    1px 1px 0 rgb(214, 142, 8);
}

.rezise20{
  font-size: 20px;
}

.pink-shadow-md {
  -webkit-text-stroke: 2px rgb(204, 0, 136);
  text-shadow: 1px 1px 0 rgb(204, 0, 136), -1px -1px 0 rgb(204, 0, 136),
    1px -1px 0 rgb(204, 0, 136), -1px 1px 0 rgb(204, 0, 136),
    1px 1px 0 rgb(204, 0, 136);
}
.purple-shadow-md {
  -webkit-text-stroke: 2px rgb(133, 6, 90);
  text-shadow: 1px 1px 0 rgb(133, 6, 90), -1px -1px 0 rgb(133, 6, 90),
    1px -1px 0 rgb(133, 6, 90), -1px 1px 0 rgb(133, 6, 90),
    1px 1px 0 rgb(133, 6, 90);
}

.subtitle-jungle-black {
  font-family: "jungle";
  color: black;
}

.button-goto {
  border: none;
  background: #7e2400;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease 0s;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding: 5px;
}

.button-goto:hover {
  opacity: 1;
}


.subtitle-jungle-alert{
  color:rgb(128, 0, 0);
}


