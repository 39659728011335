
// BUTTON 4
$yellow: rgb(216, 176, 0);
$red:rgb(209, 153, 0);

.btn-efect {
  border-color: $yellow;
  color: #fff;
  background: {
    image: linear-gradient(45deg, $yellow 50%, transparent 50%);
    position: 100%;
    size: 400%;
  }

  transition: background 300ms ease-in-out;

  &:hover {
    background-position: 0;
  }
}

// Basic Button Style
.btn_style {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid $red;
  border-radius: 0.6em;
  color: $red;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  }
}
