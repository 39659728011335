html {
  overflow-x: hidden !important;
  background-color: rgb(63, 63, 63);
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
  max-width: 1100px;
  margin: auto;
}

code {
  font-family: "Ubuntu", "Cantarell", "sans-serif", monospace;
}

@font-face {
  font-family: "sunglory";
  src: url(../assets/sunglory.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "jungle";
  src: url(../assets/JungleLand.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Amatic";
  src: url(../assets/Amatic-Bold.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Colby";
  src: url(../assets/Colby-CpExtL2t.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Seaside";
  src: url(../assets/Seaside.otf);
  font-weight: normal;
}

.sunglory {
  color: white;
  font-family: "sunglory", "sans-serif", Courier, monospace;
  line-height: 15px;
  font-size: 20px;
  text-align: start;
  padding: 0;
  margin: 0;
  width: 100%;
}

.title {
  color: #e9bf46;
  text-align: center;
  font-size: 70px;
  font-family: "Seaside", "sans-serif", Courier, monospace;
}

.subtitle {
  font-family: "Amatic", "sans-serif", Courier, monospace;
  font-size: 25px;
  text-align: center;
  color: rgb(255, 255, 255);
  padding-bottom: 20px;
}
.shadow {
  text-shadow: 1px 1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px 1px 0 rgb(255, 255, 255),
    1px 1px 0 rgb(255, 255, 255);
}

.price_text {
  font-family: "Amatic", "sans-serif", Courier, monospace;
  font-size: 70px;
}

.button-price {
  border: none;
  background: #e0bf03;
  min-width: 25px;
  min-height: 25px;
  width: 30px;
  height: 30px;
  color: #fff !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease 0s;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding-bottom: 5px;
}

.button-price:hover {
  color: #ebc803 !important;
  font-weight: 700 !important;
  letter-spacing: 3px;
  border: 1px solid #ebc803;
  background: none;
  -webkit-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}

p,
div {
  user-select: none;
}

.w-max {
  max-width: 700px;
  margin: auto;
}
.bg-white {
  background-color: rgba(255, 255, 255, 0.959);
}
.c-black {
  color: rgb(0, 0, 0);
}

/* Class of coffe svg */
.cls-1 {
  fill: none;
}

.icon-alert-button{
  max-width: 10px;
  min-width: 10px;
  width: 10px;
}
