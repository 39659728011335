.subtotal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: black;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(224, 202, 0);
}

.text_subtotal {
  position: absolute;
  top: 50%;
  left: 50%;
  align-self: center;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Amatic', 'Arial Narrow Bold', sans-serif;
  font-size: 35px;
}

.btn-pedir {
  cursor: pointer;
}

.btn-pedir:hover {
  cursor: pointer;
  background-color: #03bd7f;
}

.seleccionados {
  color: rgb(224, 202, 0);
  font-family: 'Amatic', 'Arial Narrow Bold', sans-serif;
  font-size: 35px;
  text-align: center;
  padding-top: 30px;
}

.item {
  color: rgb(224, 202, 0);
  border: rgb(224, 202, 0);
  border-radius: 10px;
  font-size: 30px;
  text-align: center;
  font-family: 'Amatic', 'Arial Narrow Bold', sans-serif;
  padding: 10px;
}

.btn-remove {
  background-color: rgb(177, 5, 5);
}

.logo_menu {
  max-width: 300px;
  padding-top: 15px;
  min-width: 200px;
}
.logo_menu svg{
  max-width: 300px;
  padding-top: 15px;
  min-width: 200px;
}

.instructions {
  font-family: 'Amatic', 'sans-serif', Courier, monospace;
  font-size: 25px;
  text-align: center;
  padding: 30px;
  color: white;
  padding-bottom: 20px;
}

.link_bottom {
  color: rgb(224, 202, 0);
}

.link_bottom:hover {
  color: rgb(20, 175, 0);
}
